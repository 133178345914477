<template lang="pug">
  #need-signature.step-component

    icon(data='~@icon/signing.svg' :fill="false")
    h1 We need your signature

    p By clicking on the "Continue to Signing" button below, you acknowledge your agreement to Kit.com's 1) General Terms of Use and User Consent, 2) Sample Collection and Uses, 3) Data Confidentiality, Retention, Ownership and Use, and 4) Recording Terms and consent to recording your interactions with the Kit application to validate your identity.

    p Beginning in the next segment, your mobile camera and microphone will be recording your movement and audio until the end of the application.

    button.button(@click="goNext()") Continue to Signing
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  beforeCreate() {
    // Everything after this step in the BDO flow must be in landscape mode, so don't unset it when
    // the component destroys
    this.$app.forceLandscape = true
  },

  mounted() {},

  methods: {
    goNext,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goNext() {
  this.$emit('complete')
}
</script>
